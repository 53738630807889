@import './common';
@import './tendrils/index';

body {
  background-color: #fff;

  &.epok-folio--intro-run {
    .epok-main-controls {
      opacity: 0;
    }
  }
}

.epok-folio__intro,
.epok-logo {
  position: absolute;
  z-index: 10;
}

.epok-folio__intro {
  font-family: monospace;
  padding: 0.75em 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
}

.epok-folio__tag {
  display: inline-block;
  line-height: 1.75em;
  padding: 0.65em 2em;
  max-width: 80%;
  border: 2px solid transparent;
  border-radius: 3px;
  margin: 0.5em 0 0.5em -3px;
  float: left;
  clear: both;
  white-space: nowrap;
  overflow: auto;
  color: #000;
  background-color: rgba(255, 255, 255, 0.8);
  transition: opacity 500ms ease-in-out;

  &.epok-hide {
    opacity: 0;
    pointer-events: none;
  }
}

.epok-folio__name {
  font-size: 1em;
  white-space: normal;
}

.epok-folio__promo {
  white-space: normal;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
  border-color: #fff;
}

.epok-logo {
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  width: 40vmax;
  max-width: 800px;
  pointer-events: none;
}

.epok-logo__build {
  stroke: #7f7f7f;
}

.epok-logo__build-part {
  opacity: 0;

  &.epok-show {
    opacity: 1;
  }
}

.epok-logo__draw {
  stroke: #fff;
  stroke-width: 4;
}

.epok-logo__background {
  display: none;
}

.epok-folio__demo {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  overflow: hidden;
}

.epok-main-controls {
  left: 2.5em;
  top: 0;
  bottom: auto;
  padding: 1.5em 0;
}

.epok-edit-controls {
  right: 2.5em;
  max-height: 90%;
  z-index: 1002;

  .close-button { bottom: 0; }
}

.epok-folio__glyph {
  opacity: 0.6;
}

em { font-style: italic; }

.epok-folio__link {
  color: inherit;
  text-decoration: underline;
  cursor: pointer;

  &:visited,
  &:hover,
  &:focus,
  &:active {
    color: inherit;
  }

  &:visited {
    opacity: 1;
  }

  &:hover,
  &:focus {
    opacity: 0.75;
  }

  &:active {
    opacity: 0.5;
  }
}
